.cookie--wrap {
    background: #222;
    align-items: stretch;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    transition: transform 150ms ease-out;
    color: #eee;
    font-size: 14px;
    line-height: 1.4;
    z-index: 999999;
    a {
        color: inherit;
    }

    //m
    width: 100%;
    @include media('>=tablet')
    {
        width: 100%;
        max-width: 900px;
        display: flex;
        justify-content: space-between;
    }
}

.cookie--wrap__on {
    transform: translateX(-50%);
}

.cookie--body {
    padding: 20px;
}

.cookie--chiudi {
    background: #2871a0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    cursor: pointer;
    &:hover {
        background: darken(#2871a0,10%);
    }
}

.cookie--btn {
    text-transform: uppercase;
    font-weight: bold;
}
