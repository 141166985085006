@font-face {
    font-family: 'Barlow';
    src: url('/assets/font/Barlow-Light.woff2') format('woff2'),
        url('/assets/font/Barlow-Light.woff') format('woff'),
        url('/assets/font/Barlow-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/font/Barlow-LightItalic.woff2') format('woff2'),
        url('/assets/font/Barlow-LightItalic.woff') format('woff'),
        url('/assets/font/Barlow-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/font/Barlow-Regular.woff2') format('woff2'),
        url('/assets/font/Barlow-Regular.woff') format('woff'),
        url('/assets/font/Barlow-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/font/Barlow-Italic.woff2') format('woff2'),
        url('/assets/font/Barlow-Italic.woff') format('woff'),
        url('/assets/font/Barlow-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/font/Barlow-Bold.woff2') format('woff2'),
        url('/assets/font/Barlow-Bold.woff') format('woff'),
        url('/assets/font/Barlow-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/font/Barlow-BoldItalic.woff2') format('woff2'),
        url('/assets/font/Barlow-BoldItalic.woff') format('woff'),
        url('/assets/font/Barlow-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
