@import '_utility';

footer {
    padding: 20px;
    border-bottom: 15px solid $blu;

    @include media('>=desktop') {
        margin-left: $gap;
        padding: 20px 0;
    }
}

.footer--content {
    position: relative;
}

.footer--privacy {
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
}

.credits {
    display: block;
    position: relative;
    margin-top: 40px;

    @include media('>tablet') {
        margin-top: 0;
        position: absolute;
        bottom: 0;
        right: 20px;
    }

    img {
        width: 70px;
        height: auto;
    }
}
