@import '_reset';
@import '_utility';
@import '_font';
@import '_cookie';
@import '_nav';
@import '_footer';


body {
	font-family: $barlow;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
	color: $color;
}

a {
	color: inherit;
}

b, strong { font-weight: 700; }
i { font-style: italic; }

ul, ul li {
	margin: 0; padding: 0; list-style-type: none;
}

.freeze {
    overflow-y: hidden;
}

.nomargin {
	margin: 0 !important;
}

.desktop {
	display: none;

	@include media('>tablet') {
		display: block;
	}
}

.mobile {
	display: block;
	text-align: center;

	@include media('>tablet') {
		display: none;
	}
}

.img--block {
	display: block;
	width: auto;
	max-width: 100%;

	img {
		width: auto;
		max-width: 100%;
	}
}

.spacer {
	height: 5px;

	@include media('>tablet') {
		height: 40px;
	}
}

.main--wrap {
	overflow-x: hidden;
	padding-top: $altezzaHeaderMobile;

	@include media('>tablet') {
		padding-top: $altezzaHeader;
	}
}

.row {
    margin-bottom: 40px;
}

.layout {
    @include gabbia;
}

.title {
    padding-top: 40px;
    font-size: 36px;
    font-weight: bold;
    color: $grigio;
    letter-spacing: -2px;
    text-align: center;
    margin-bottom: 40px;

    span { color: $blu; }

	@include media('>tablet') {
		font-size: 56px;
		padding-top: 80px;
		margin-bottom: 80px;
	}
}

.box {
	display: flex;
	flex-wrap: wrap;
}

.side {
	width: 100%;
	margin-bottom: 20px;

	@include media('>=desktop') {
		width: 50%;
		margin-bottom: 0;
		&:nth-child(odd) { padding-right: 20px; }
		&:nth-child(even) { padding-left: 20px; }
	}

	img {
		margin: 0 auto;
	}
}

.txt {
	font-size: 19px;

	p {
		margin-bottom: 20px;
	}

	b, strong {
		color: $blu;
	}
}

.pulsante--wrap {
    text-align: center;
}

.pulsante {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: $blu;
    padding: 6px 14px;
    text-align: center;
	letter-spacing: 1px;
    line-height: 1.5;
    border: 0;
    border-radius: 20px;
    width: auto;
    max-width: 100%;
    margin-bottom: 20px;
    cursor: pointer;

    @include media('>=tablet') {
        font-size: 16px;
        letter-spacing: 1px;
        padding: 10px 20px;
    }

    &:hover {
        background: darken($blu, 10%);
    }

    &.negative {
        background: #fff;
        color: $blu;
        &:hover {
            background: darken(#fff, 10%);
        }
    }
}

.artwork {
	background: linear-gradient(to bottom, transparent 0%, $blu 100%), url('/assets/img/home-artwork.jpg'), $blu;
	background-repeat: no-repeat, no-repeat;
	background-position: center center, center center;
	background-size: cover, cover;
	color: #fff;

	@include media('>=1600px') {
		height: 550px;
		background: url('/assets/img/home-artwork.jpg'),$blu;
		background-repeat: no-repeat;
		background-position: 10% center;
		background-size: auto 100%;
	}
}

.artwork--layout {
	width: 100%;
	@include gabbia;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 300px 0 40px 0;

	@include media('>=1600px') {
		padding: 0;
	}
}

.artwork--content {
	height: 100%;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	width: 100%;
	padding: 0 20px;
	max-width: 400px;
}

.artwork--deco {
	width: 100%;
	background: linear-gradient(to right, transparent 0%, transparent 50%, transparent calc(50% + 1px), #fff calc(50% + 1px), #fff calc(50% + 2px), transparent calc(50% + 2px), transparent 100%);
}

.artwork--info {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.artwork--txt {
	width: 100%;
	font-size: 18px;
	margin-bottom: 20px;

	@include media('>=tablet') {
		font-size: 24px;
	}
}

.artwork--firma {
	width: 100%;
	font-size: 14px;
	font-weight: 300;
	font-style: italic;
	text-align: right;

	@include media('>=tablet') {
		font-size: 18px;
	}
}


.breadcrumbs--wrap {
	margin-bottom: 50px;
}

.breadcrumbs--item {
	text-transform: uppercase;
	font-weight: bold;
	color: $blu;
	font-size: 13px;
	text-decoration: none;
}

.pagina--title {
	font-size: 35px;
	font-weight: 300;
	letter-spacing: -1px;
	text-transform: uppercase;
	color: $blu;
	padding-bottom: 20px;
	margin-bottom: 30px;
	position: relative;
	line-height: 1.2;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 25%;
		border-bottom: 5px solid $blu;
	}

	@include media('>=tablet') {
		font-size: 47px;
	}
}




.bullet--wrap {
	display: flex;
	flex-wrap: wrap;
}

.bullet--item {
	width: 100%;
	margin-bottom: 40px;

	@include media('>=tablet') {
		width: 50%;

		&:nth-child(odd) {
			padding-right: 20px;
		}

		&:nth-child(even) {
			padding-left: 20px;
		}
	}
}

.bullet--number {
	display: inline-block;
	font-size: 20px;
	color: $blu;
	font-weight: bold;
	padding-bottom: 20px;
	border-bottom: 1px solid $blu;
	margin-bottom: 20px;
}

.bullet--title {
	width: 100%;
	text-transform: uppercase;
	margin-bottom: 20px;
	font-size: 28px;
	font-weight: 300;
	color: $blu;
}



.splash--wrap {
    background: linear-gradient(to bottom, #fff 0%, #fff 10%, $blu 10%, $blu 90%, #fff 90%, #fff 100%);
    text-align: center;
}

.splash--layout {
    @include gabbia;
}

.splash--content {
    box-shadow: 0px 0px 70px -10px rgba(0,0,0,0.4);
}
