@import '_utility';

.nav--wrap {
    width: 100%;
    position: fixed;
    z-index: $zindexHeader;
    height: $altezzaHeaderMobile;
    background: #fff;
    border-top: 7px solid #f0f0f0;
    border-bottom: 1px solid $grigio;
    transition: all 250ms ease-in-out;

    @include media('>tablet') {
        height: $altezzaHeader;
    }
}

.nav--layout {
    @include gabbia;
}

.nav--top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 0 0;
    transition: all 250ms ease-in-out;

    @include media('>tablet') {
        padding: 30px 0 40px 0;
    }
}

.lang--wrap {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 70px;

    @include media('>tablet') {
        display: flex;
    }
}

.lang--item {
    padding: 5px 10px 7px 10px;
    color: $grigio;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    background: #f0f0f0;
    width: 50%;
    text-align: center;

    &.current {
        background: $blu;
        color: #fff;
    }
}

.lang--wrap__mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;

    @include media('>tablet') {
        display: none;
    }
}

.lang--item__mobile {
    padding: 5px 10px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    background: $blu;
    width: 35px;
    text-align: center;

    &.current {
        background: darken($blu,10%);
        color: #fff;
    }
}

.nav--top__txt {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
}

.nav--top__logo svg {
    padding: 0 40px;
    width: 45px;
    height: 23px;
    transition: all 250ms ease-in-out;

    @include media('>tablet') {
        width: 125px;
        height: 60px;
    }
}

nav {
    display: block;
    position: fixed;
    top: $altezzaHeaderMobile;
    left: 0;
    height: calc(100% - #{$altezzaHeaderMobile} - 54px);
    overflow-y: auto;
    width: 100%;
    background-color: $blu;
    z-index: $zindexNav;
    transform: translateX(110%);
    box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);
    transition: all 250ms ease-in-out;

    @include media('>tablet') {
        position: relative;
        top: auto;
        height: auto;
        background-color: transparent;
        transform: none;
        display: flex;
        justify-content: center;
        position: relative;
        bottom: -11px;
        box-shadow: none;
        transition: all 250ms ease-in-out;
    }
}

nav.nav--de {
    @include media('>tablet') {
        align-items: center;
        background: #fff;
        overflow-x: hidden;
    }

    .nav--item {
        @include media('>tablet') {
            font-size: 0.8rem;
            text-align: center;
        }
    }
}

.nav--item {
    display: block;
    padding: 15px 20px 15px 20px;
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;

    &:hover {
        color: $grigio;
    }

    &.first {padding-left: 20px;}
    &.last {padding-right: 0;}

    &.current {
        color: #fff;
        background: darken($blu,10%);
    }

    @include media('>tablet') {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: bold;
        background: #fff;
        color: $color;
        font-size: 1rem;

        &:hover {
            color: $blu;
        }

        &.first {padding-left: 80px;}
        &.last {padding-right: 80px;}

        &.current {
            color: $blu;
            background: #fff;
        }
    }
}

nav.show {
    transform: translateX(0%);
}


.nav--bottom__mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 20px 20px 40px 20px;
    line-height: 1.5;
    font-size: 1.2rem;
    width: 100%;
    display: block;
    text-align: center;

    @include media('>tablet') {
        display: none;
    }
}

.nav--bottom__mobile__tel {
    a {
        font-size: 1rem;
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        svg {
            width: 1rem;
            height: 1rem;
            margin-right: 10px;

            path {
                fill: $blu;
            }
        }
    }
}

.nav--bottom__mobile__mail {
    font-size: 1rem;
    letter-spacing: 1px;
}


// nav on scroll
.nav--wrap.shrink {
    @include media('>tablet') {
        height: $altezzaHeaderShrink;
        transition: all 250ms ease-in-out;

        .nav--top {
            padding: 15px 0 10px 0;
            transition: all 250ms ease-in-out;
        }

        .nav--top__logo svg {
            padding: 0 40px;
            width: 60px;
            height: 30px;
            transition: all 250ms ease-in-out;
        }

        nav {
            bottom: 0;
            transition: all 250ms ease-in-out;
        }
    }
}






//pulsante menu
.nav--btn {
    display: inline-block;
    position: fixed;
    bottom: 30px;
    right: 30px;
    overflow: hidden;
    vertical-align: middle;
    width: 48px;
    height: 48px;
    background: $blu;
    border-radius: 100%;
    // box-shadow: 0 4px 5px -1px rgba(0,0,0,0.5);
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
    text-align: center;
    padding: 0.3rem 0;
    font-size: 0;
    cursor: pointer;
    z-index: $zindexNavButton;
    transition: right, background 250ms ease-in-out;
    -moz-transition: right, background 250ms ease-in-out;
    -webkit-transition: right, background 250ms ease-in-out;

    @include media('>tablet') {
        display: none
    }

    &:before {
        font-size: 2rem;
        color: #fff;
    }

    &:focus {
        outline: none;
    }

    span {
        display: block;
        position: absolute;
        top: 22px;
        left: 25%;
        right: 25%;
        height: 4px;
        background: #fff;

        &:after, &:before {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #fff;
            content: "";
        }

        &:after {
            bottom: -8px;
        }

        &:before {
            top: -8px;
        }
    }
}

//struttura hamburger
.hamburger {
    span {
        transition: background 0ms 250ms;
        -moz-transition: background 0ms 250ms;
        -webkit-transition: background 0ms 250ms;

        &:before, &:after {
            transition-duration: 250ms, 250ms;
            -moz-transition-duration: 250ms, 250ms;
            -webkit-transition-duration: 250ms, 250ms;
            transition-delay: 250ms, 0ms;
            -moz-transition-delay: 250ms, 0ms;
            -webkit-transition-delay: 250ms, 0ms;
        }

        &:before {
            transition-property: top, transform;
            -moz-transition-property: top, transform;
            -webkit-transition-property: top, transform;
        }

        &:after {
            transition-property: bottom, transform;
            -moz-transition-property: bottom, transform;
            -webkit-transition-property: bottom, transform;
        }
    }
}

//stato attivo dell'hamburger
.hamburger.active {
    span {
        background: none;

        &:before, &:after {
            transition-delay: 0ms, 250ms;
            -moz-transition-delay: 0ms, 250ms;
            -webkit-transition-delay: 0ms, 250ms;
        }

        &:before {
            top: 0;
            transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        &:after {
            bottom: 0;
            transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
    }
}
